"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultDashboardRoutes = exports.teamDashboardRoutes = exports.defaultAdminRoutes = void 0;
const react_1 = require("react");
const appConstants_1 = require("../../constants/appConstants");
const dashboard_1 = require("../loaders/dashboard");
const dashboardPath = '/dashboard';
exports.defaultAdminRoutes = [`${dashboardPath}`].map(path => ({
    path,
    component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/dashboard')))),
    loader: dashboard_1.dashboardLoader,
    roles: [...appConstants_1.defaultAllowedRoles],
}));
exports.teamDashboardRoutes = {
    path: `${dashboardPath}/team/:teamId`,
    component: (0, react_1.lazy)(() => Promise.resolve().then(() => __importStar(require('../../components/pages/dashboard')))),
    loader: dashboard_1.dashboardLoader,
    roles: [...appConstants_1.defaultAllowedRoles, appConstants_1.teamAdmin, appConstants_1.manager],
};
exports.defaultDashboardRoutes = [...exports.defaultAdminRoutes, exports.teamDashboardRoutes];
